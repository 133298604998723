Array::remove = ->
    what = undefined
    a = arguments
    L = a.length
    ax = undefined
    while L and @length
        what = a[--L]
        while (ax = @indexOf(what)) != -1
            @splice ax, 1
    this

# ---
# generated by js2coffee 2.2.0
$(document).ready ->
    $('[data-toggle="tooltip"]').tooltip()
    $(window).scroll ->
        if $(this).scrollTop() > 160
            $('body').addClass 'scroll'
        else
            $('body').removeClass 'scroll'

    $('.slick.slick-4').slick(
        slidesToShow: 4,
        slidesToScroll: 1
        autoplay: true
        dotted: true
        autoplaySpeed: 2000
        pauseOnHover: false
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }]
    );
    $('#toggleMenu').on('click', ()->
        $(this).toggleClass('is-active')
        $('body').toggleClass('mobile-menu-open')
    )
    $('.slick.slick-3').not('.center-mode').slick(
        slidesToShow: 3,
        slidesToScroll: 1
        autoplay: true
        dotted: true
        pauseOnHover: false
        autoplaySpeed: 2000);
    $('.slick.slick-3.center-mode').slick(
        slidesToShow: 3,
        slidesToScroll: 1
        autoplay: true
        dotted: true
        centerMode: true
        centerPadding: '0'
        autoplaySpeed: 5000
        pauseOnHover: false
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                    centerMode: false
                }
            }]
    );
    countUp = (objectCase)->
        slide = $(objectCase).find('.slick-current .slick-header')
        id = $(slide).attr('id')
        countTo = parseInt($(slide).data('count-to'))
        if countTo < 50
            countDelay = 10
            countStep = 1
        else if countTo < 1000
            countDelay = 10
            countStep = 2
        else if countTo < 10000
            countDelay = 10
            countStep = 100
        else if countTo < 30000
            countDelay = 10
            countStep = 1000
        else
            countDelay = 10
            countStep = 200
        if !$(slide).hasClass('counted')
            setInterval(()->
                now = parseInt($('#' + id).html());
                if now < countTo
                    now = now + countStep;
                    $('#' + id).html(now)
                else
                    $(slide).addClass('counted')
                    $('[data-id*=' + id + ']').html(now)
            , countDelay)
    #    countUp('.slick-counter')
    if localStorage.getItem('cookie')
        $('#cookie-alert').addClass('d-none')
    $('#cookie-alert a.btn').on 'click', () ->
        $('#cookie-alert').addClass('d-none')
        localStorage.setItem('cookie', 'done')
    $('.center-mode.history').on 'afterChange', () ->
        $('.history .slick-slide').each((key, item)->
            $(this).removeClass('slide-color-0').removeClass('slide-color-1').removeClass('slide-color-2')
        )
        $('.history .slick-active').each((key, item)->
            $(item).addClass('slide-color-' + key)
        )
    $('.slick-counter').on 'afterChange', () ->
        countUp(this)
    $('.slick-counter').on 'swipe', () ->
        countUp(this)
    $('.share-url').on('click', ()->
        text = $('#link-to-share').val()
        navigator.clipboard.writeText(text).then (->
            alert "Skopiowano link do schwoka, możesz go wkleić w dowolnym miejscu"
            return
        ), (err) ->
            return
    )
    maps = []
    markers = []
    $('.map-container').each((key, item)->
        lat = $(this).data('lat');
        lng = $(this).data('lng');
        id = $(this).data('id')
        zoom = $(this).data('zoom')
        markerImg = $(this).data('marker');
        devhero = {lat: lat, lng: lng};
        maps[key] = new google.maps.Map(document.getElementById('mapFullWidth-' + id), {
            zoom: zoom,
            center: devhero,
            scrollwheel: false,
            draggable: true,
            styles: [{"elementType": "geometry", "stylers": [{"color": "#f5f5f5"}]},
                {"elementType": "labels.icon", "stylers": [{"visibility": "on"}]},
                {"elementType": "labels.text.fill", "stylers": [{"color": "#616161"}]},
                {"elementType": "labels.text.stroke", "stylers": [{"color": "#f5f5f5"}]}, {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels.text.fill",
                    "stylers": [{"color": "#bdbdbd"}]
                }, {"featureType": "poi", "elementType": "geometry", "stylers": [{"color": "#eeeeee"}]},
                {"featureType": "poi", "elementType": "labels.text.fill", "stylers": [{"color": "#757575"}]}, {
                    "featureType": "poi.attraction",
                    "elementType": "labels.icon",
                    "stylers": [{"color": "#ffae3d"}, {"visibility": "on"}]
                }, {
                    "featureType": "poi.business",
                    "elementType": "labels.icon",
                    "stylers": [{"color": "#23d100"}, {"visibility": "on"}]
                }, {
                    "featureType": "poi.government",
                    "elementType": "labels.icon",
                    "stylers": [{"color": "#008a3c"}, {"visibility": "on"}]
                }, {"featureType": "poi.medical", "elementType": "labels.icon", "stylers": [{"color": "#ffea00"}]},
                {"featureType": "poi.park", "elementType": "geometry", "stylers": [{"color": "#e5e5e5"}]}, {
                    "featureType": "poi.park",
                    "elementType": "labels.icon",
                    "stylers": [{"color": "#ffa200"}, {"visibility": "on"}]
                }, {"featureType": "poi.park", "elementType": "labels.text.fill", "stylers": [{"color": "#9e9e9e"}]}, {
                    "featureType": "poi.place_of_worship",
                    "elementType": "labels.icon",
                    "stylers": [{"visibility": "on"}]
                }, {
                    "featureType": "poi.school",
                    "elementType": "labels.icon",
                    "stylers": [{"color": "#ffa200"}, {"visibility": "on"}]
                }, {
                    "featureType": "poi.sports_complex",
                    "elementType": "labels.icon",
                    "stylers": [{"color": "#ffa200"}, {"visibility": "on"}]
                }, {"featureType": "road", "elementType": "geometry", "stylers": [{"color": "#ffffff"}]},
                {"featureType": "road.arterial", "elementType": "labels.text.fill", "stylers": [{"color": "#757575"}]},
                {"featureType": "road.highway", "elementType": "geometry", "stylers": [{"color": "#dadada"}]},
                {"featureType": "road.highway", "elementType": "labels.text.fill", "stylers": [{"color": "#616161"}]},
                {"featureType": "road.local", "elementType": "labels.text.fill", "stylers": [{"color": "#9e9e9e"}]},
                {"featureType": "transit.line", "stylers": [{"visibility": "on"}]},
                {"featureType": "transit.line", "elementType": "geometry", "stylers": [{"color": "#e5e5e5"}]},
                {"featureType": "transit.station", "elementType": "geometry", "stylers": [{"color": "#eeeeee"}]},
                {"featureType": "transit.station", "elementType": "labels.icon", "stylers": [{"visibility": "on"}]},
                {"featureType": "water", "elementType": "geometry", "stylers": [{"color": "#c9c9c9"}]},
                {"featureType": "water", "elementType": "labels.icon", "stylers": [{"color": "#ffa200"}]},
                {"featureType": "water", "elementType": "labels.text.fill", "stylers": [{"color": "#9e9e9e"}]}]
        });
        if $(this).hasClass('map-collection')
            search = "#point_collection_" + id + " .single-point"
            $(search).each((key2, data2)->
                locallat = parseFloat($(this).data('lat'));
                locallng = parseFloat($(this).data('lng'));
                label = $(this).html();
                localid = $(this).data('id');
                kk = key + "-" + key2
                localposition = {lat: locallat, lng: locallng}
                infowindow = new google.maps.InfoWindow({
                content: '<span class="text-primary">' + label + '</h6>',
                });
                markers[kk] = new google.maps.Marker({
                    position: localposition,
                    icon: {url: markerImg},
                    map: maps[key],
                    title: label
                })
                setTimeout (->
                    markers[kk].setAnimation(4)
                ), key2 * 200
                markers[kk].addListener("click", () ->
                    infowindow.open(maps[key], markers[kk])
                )
            )
        else
            markers[key] = new google.maps.Marker({
                position: devhero,
                icon: {url: markerImg},
                map: maps[key]
            })
    )
#
#    )
